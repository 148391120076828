import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
//Styles
import './HighlightsComponent.scss'
//SVG image
import SvgElement from '../svg-images/Circles-blue.svg'
import SvgElementCircle from '../svg-images/Circle-Stroke-grey.svg'

import { useCurrentWidth } from '../hooks/useResize'

/**
 * HighlightsComponent
 * 
 * @param description
 * @param title
 * @param link
 * @param image
 * @param mobileImage
 * @param imagePosition // Boolean: true -> image is on the left; false -> image is on the right
 * @param removeGraphicElement
 * @param language
 * @returns {JSX}
 */
export default function HighlightsComponent({ description, title, link, image, mobileImage, imagePosition, language, removeGraphicElement, removePaddingTop }) {

  const windowWidth = useCurrentWidth()

  const { locale } = language;

  //limiting description to 1260 caracters
  const limitedDescription = description.substring(0, 1260);

  return (
    <section className={`highlights ${removePaddingTop ? 'highlights--no-padding' : ''}`}>
      <div className="content-wrapper">
        <div className={`highlights__wrapper${imagePosition ? ' highlights__wrapper--image-left' : ''}`}>

          <SvgElementCircle className="highlights__circle-svg" />

          <div className={`highlights__image-wrapper`}>
            <Img
              className={`highlights__image ${imagePosition ? 'highlights__image--image-left' : ''}`}
              fluid={
                windowWidth < 1024
                  ? mobileImage?.localFile?.childImageSharp
                    ? mobileImage?.localFile?.childImageSharp?.fluid
                    : image?.localFile?.childImageSharp?.fluid
                  : image?.localFile?.childImageSharp?.fluid
              }
            />
          </div>

          <div className='highlights__headlines-wrapper'>
            <div className="highlights__headlines">
              <h3 className="highlights__headlines-title">{title}</h3>
              <p className="highlights__headlines-subtitle">{
                description.length > limitedDescription.length
                  ? `${limitedDescription}...`
                  : description
              }</p>
              {link && <a href={link} className="read-more-link--light">{locale === 'is_IS' ? 'Lestu meira' : 'Read more'}</a>}
            </div>
            {!removeGraphicElement && <SvgElement className={`highlights__svg ${imagePosition ? 'highlights__svg--left' : ''}`} />}
          </div>
        </div>
      </div>
    </section>
  )
}

HighlightsComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object.isRequired
}
