import React, { useState } from 'react'
import './HomeHiglightFAQ.scss'
import FAQhomepageComponent from './FAQhomepageComponent'

export default function HomeHiglightFAQ({ title, faqRepeater, removePaddingTop }) {

  const [cardIndex, setCardIndex] = useState()


  return (
    <section className={`highlights ${removePaddingTop ? 'highlights--no-padding' : ''}`}>
      <div className="content-wrapper">
        <div className={`home-faq-highlights__wrapper`}>



          <div className='home-faq-highlights__headlines-wrapper home-faq-highlights__wrapper--image-left'>
            <div className="home-faq-highlights__headlines">
              <h3 className="home-faq-highlights__headlines-title">{title}</h3>
            </div>
          </div>


          {faqRepeater.map((faq, index) =>
            <div
              className='home-faq-highlights__faq-wrapper'
              value={index}
              key={index}>
              <FAQhomepageComponent

                key={index}
                faq={faq}
                index={index}
                cardIndex={cardIndex}
                setCardIndex={setCardIndex}
              />
            </div>
          )}






        </div>
      </div>
    </section>
  )
}
