import React from 'react'
import PropTypes from 'prop-types'

import SvgElement from '../svg-images/Circles-blue.svg'
import SvgElementCircle from '../svg-images/Circle-Stroke-grey.svg'

import './HighlightsText.scss'


/**
 * HighlightsText is similar to Highlights component, but without image.
 * 
 * @param {String} title 
 * @param {String} description
 * @param {Boolean} removeGraphicElementremoveGraphicElement
 * @param {String} url
 * @param {Object} language
 * @returns {JSX}
 */
export default function HighlightsText({ title, description, removeGraphicElement, url, language, removePaddingTop }) {

  const { locale } = language;

  const limitedDescription = description.substring(0, 1260);

  return (
    <section className={`highlights ${removePaddingTop ? 'highlights--no-padding' : ''}`}>
      <div className='content-wrapper'>
        <div className='highlights-text__wrapper'>

          <SvgElementCircle className="highlights__circle-svg" />

          <div className="highlights-text__headlines">
            <h3 className="highlights-text__headlines-title">{title}</h3>
            <p className="highlights-text__headlines-subtitle">{
              description.length > limitedDescription.length
                ? `${limitedDescription}...`
                : description
            }</p>

            {url && <a href={url} className="read-more-link--light">{locale === 'is_IS' ? 'Lestu meira' : 'Read more'}</a>}

            {!removeGraphicElement && <SvgElement className={`highlights-text__svg`} />}
          </div>
        </div>

      </div>
    </section>
  )
}

HighlightsText.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  removeGraphicElement: PropTypes.bool,
  link: PropTypes.string
}