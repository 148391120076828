import React from 'react'
import PropTypes from 'prop-types'
import HomeHero from './HomeHero'
import HomeBanner from './HomeBanner'
import LatestNewsComponent from './LatestNewsComponent'
import BannerSlider from './BannerSlider'
import HighlightsComponent from './HighlightsComponent'
import LinkCards from './LinkCards'
import CookiePopUp from './CookiePopUp'
import HeroImage from './HeroImage'
import HomeHiglightFAQ from './HomeHiglightFAQ'
import HighlightsText from './HighlightsText'

import Header from './Header'
import Footer from './Footer'

export default function FrontPageLayout({ isFrontPage, homeComponents, menuData, language, translations, footerOptionsPage }) {

  let removePaddingTop = false

  return (
    <>
      <Header isFrontPage={isFrontPage} menuData={menuData} language={language} translations={translations} />
      <main>
        {
          homeComponents.map((content, index) => {

            const isHighlightComponent = (typename) => {
              switch (typename) {
                case 'WpPage_Homeflexiblecontent_Homecontent_HighlightsComponent':
                  return true
                case 'WpPage_Homeflexiblecontent_Homecontent_HighlightFaq':
                  return true
                case 'WpPage_Homeflexiblecontent_Homecontent_HighlightsText':
                  return true
                default:
                  return false
              }
            }

            const isCurrentComponentHighlight = isHighlightComponent(content?.__typename)
            const isNextComponentHighlight = isHighlightComponent(homeComponents[index + 1]?.__typename)

            if (isCurrentComponentHighlight && isNextComponentHighlight) {
              removePaddingTop = true
            }
            else {
              removePaddingTop = false
            }

            switch (content?.__typename) {
              case "WpPage_Homeflexiblecontent_Homecontent_Homehero":
                const { image } = content
                return <HomeHero title={content.title} subtitle={content.subtitle} heroImage={image} key={index} />
              case "WpPage_Homeflexiblecontent_Homecontent_HomeBanner":
                const { desktopImage, mobileImage } = content
                return <HomeBanner url={content.url} desktopImage={desktopImage} mobileImage={mobileImage} key={index} />
              case "WpPage_Homeflexiblecontent_Homecontent_LatestNews":
                return <LatestNewsComponent key={index} language={language} />
              case "WpPage_Homeflexiblecontent_Homecontent_BannerSlider":
                return <BannerSlider slider={content.slider} key={index} />
              case "WpPage_Homeflexiblecontent_Homecontent_HighlightsComponent":
                return <HighlightsComponent removePaddingTop={removePaddingTop} removeGraphicElement={content.removeGraphicElement} imagePosition={content.imagePosition} mobileImage={content.mobileImage} description={content.description} title={content.title} link={content.url} image={content.image} language={language} key={index} />
              case "WpPage_Homeflexiblecontent_Homecontent_LinkCards":
                return <LinkCards linkCards={content.linkCards} mainLinkCard={content.mainLinkCard} language={language} key={index} />
              case "WpPage_Homeflexiblecontent_Homecontent_HeroImage":
                return <HeroImage mobileImage={content.mobileImage} desktopImage={content.desktopImage} key={index} />
              case "WpPage_Homeflexiblecontent_Homecontent_HighlightFaq":
                return <HomeHiglightFAQ removePaddingTop={removePaddingTop} title={content.faqTitle} faqRepeater={content.faqRepeater} key={index} />
              case "WpPage_Homeflexiblecontent_Homecontent_HighlightsText":
                return <HighlightsText removePaddingTop={removePaddingTop} title={content.title} description={content.description} removeGraphicElement={content.removeGraphicElement} url={content.url} language={language} key={index} />
              default:
                return null
            }
          })
        }
        <CookiePopUp language={language} />
      </main>
      <Footer menuData={menuData} language={language} footerOptionsPage={footerOptionsPage} />
    </>
  )
}

FrontPageLayout.propTypes = {
  homeComponents: PropTypes.array,
  language: PropTypes.object

}