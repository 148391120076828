import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
//Utils
import { getMonthName } from '../utils/utils'
//Defautl image
import defaultImage from '../images/default-discount.png'
//Styles
import './LatestNewsComponent.scss'

/**
 * In this component I am using static query to get all news and then I filter it bu language
 * Better option will be to filter it in page query, but I don't know how inser parameter for that
 * So I am passing language and then filter query by language.locale
 * @param {object} language 
 */
export default function LatestNewsComponent({ language }) {

  const { locale } = language

  const isIcelandic = locale === 'is_IS'

  // Url for archive page & prefix for single page uri
  const singleNewsUriPrefix = isIcelandic ? '/frettir/' : '/en/news/'
  // Text vaiables 
  const titleText = isIcelandic ? 'Nýjustu fréttir' : 'Latest news'
  const seeAllText = isIcelandic ? 'Allar fréttir' : 'See all news'

  //Static query where I get all news 
  const allNews = useStaticQuery(graphql`
    query allNewsQuery {
      allWpPost {
        posts: nodes {
          title
          date(formatString: "MMMM DD, YYYY")
          uri
          slug
          featuredImage {
            node {
              file: localFile {
                newsImage: childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          language {
            locale
          }
        }
      }
    }
  `)

  const { allWpPost: { posts } } = allNews
  //When user update post gatsby set it as first array item, so now we have to sort it by date 
  //Then get only current langugage post & return 3 newest posts
  const latestPost = posts.sort((a, b) => new Date(b.date) - new Date(a.date))
    .filter(post => post.language.locale === locale)
    .slice(0, 3)
  return (
    <section className="latest-news">
      <div className="latest-news__wrapper content-wrapper">
        <h3>{titleText}</h3>
        <div className="latest-news__item-wrapp">
          {latestPost.map((item, key) => <div className="latest-news__item" key={key}>
            <Link to={singleNewsUriPrefix + item.slug} >
              {item.featuredImage
                ? <Img className="latest-news__item-image" fluid={item.featuredImage.node.file.newsImage.fluid} alt={item.title} />
                : <img src={defaultImage} alt={item.title} className="latest-news__item-image" />
              }
            </Link>
            <p className="small">{isIcelandic ? getMonthName(item.date) : item.date}</p>
            <Link className="latest-news__item-title" to={singleNewsUriPrefix + item.slug}>
              <h5>
                {item.title.length < 140 ? item.title : item.title.substring(1, 140)}
              </h5>
            </Link>
          </div>)}
        </div>
        <div className="latest-news__link">
          <Link to={singleNewsUriPrefix} className="read-more-link--light">{seeAllText}</Link>

        </div>
      </div>
    </section>
  )
}

LatestNewsComponent.propTypes = {
  language: PropTypes.objectOf(PropTypes.string)
}