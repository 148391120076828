import React from 'react'
import BackgroundImage from 'gatsby-background-image'

import './HeroImage.scss'

import { useCurrentWidth } from '../hooks/useResize'

/**
 * HeroImage - component used as hero section. Has 2 images, for mobile and desktop.
 * 
 * @param {GatsbyImage} mobileImage 
 * @param {GatsbyImage} desktopImage
 * @returns {JSX}
 */
export default function HeroImage({ mobileImage, desktopImage }) {

  const windowWidth = useCurrentWidth()

  return (
    <section className='hero-image'>
      <BackgroundImage
        className="hero-image__background"
        fluid={
          windowWidth >= 580
            ? desktopImage?.localFile?.childImageSharp.fluid
            : mobileImage?.localFile?.childImageSharp.fluid
        }
      />
    </section>
  )
}
