import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { useCurrentWidth } from '../hooks/useResize'
import './BannerSlider.scss'


export default function BannerSlider({ slider }) {
  
  const windowWidth = useCurrentWidth()
  slider.slice(0, 5);

  //this updates activeSlider state to +1 every 5 seconds so it can change link and image and create slider effect
  const [activeSlider, setActiveSlider] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      activeSlider + 1 < slider.length
        ? setActiveSlider(activeSlider + 1)
        : setActiveSlider(activeSlider => activeSlider = 0)
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlider])

  return (
    <section className="slider">
      <div className="slider__wrapper content-wrapper">
        {
          slider.map((slide, index) => {
            const isDesktopOrTablet = windowWidth > 579;
            const imageFluid = isDesktopOrTablet
              ? slide?.bannerImage?.localFile?.childImageSharp?.fluid
              : slide?.bannerImageMobile?.localFile?.childImageSharp?.fluid;
  
            // Check if both url and imageFluid are available
            if (imageFluid) {
              return (
                <div
                  className={`slider__link${activeSlider === index
                    ? ' slider__link--active' : ''}${activeSlider - 1 === index || (index === slider.length - 1 && activeSlider === 0)
                      ? ' slider__link--was-active' : ''}`}
                  key={index}
                >
                  <a href={slide.url ? slide.url : ""} rel="noreferrer" target={slide.url ? "_blank" : "_self"}>
                    <Img className='slider__link-img' fluid={imageFluid} />
                  </a>
                </div>
              );
            }
  
            // Optionally, return null if url or imageFluid is missing
            return null;
          })
        }
      </div>
    </section>
  );
  
}

BannerSlider.propTypes = {
  slider: PropTypes.array
}
